<template>
  <div @click="isOpened = !isOpened">
    <div
      class="item-wrapper"
      :style="changeGridTemplateColumns"
      @click.stop
    >
      <div v-if="timeEntry.length > 1">
        <div
          v-if="timeEntry.length > 1 && oneItem"
          class="counter-entries"
          :class="isOpened ? 'counter' : ''"
          @click="isOpened = !isOpened"
        >
          <p>{{ timeEntry.length }}</p>
        </div>
        <div v-else />
      </div>

      <el-input
        :value="tempTitle"
        class="new-task-title"
        placeholder="Add description"
        @input="onTitleChange"
        @blur="saveInputChange"
      />
      <div :class="isShowProjectOrTaskTitle ? 'show' : 'hidden'">
        <FolderBtn
          :time-entry="timeEntry"
          @show="showPopover = true"
          @hide="showPopover = false"
        />
      </div>
      <div :class="showPopover ? 'show' : 'hidden'">
        <TagBtn
          :time-entry="timeEntry"
          @show="showPopover = true"
          @hide="showPopover = false"
        />
      </div>
      <div :class="isShowBillable ? 'show' : 'hidden'">
        <BillableBtn
          :time-entry="timeEntry"
          :main-entry="mainEntry"
          @show="showPopover = true"
          @hide="showPopover = false"
        />
      </div>

      <div
        v-if="isGroup"
        class="time-range-isGroup"
        @click="isOpened = !isOpened"
      >
        {{ timeRange[0] }}
        <p style="margin: 0 9px">
          -
        </p>
        {{ timeRange[1] }}
      </div>
      <el-date-picker
        v-else
        v-model="timeRange"
        type="datetimerange"
        start-placeholder="Start"
        end-placeholder="End"
        format="HH:mm"
        value-format="timestamp"
        align="right"
        :clearable="false"
        :default-time="['12:00:00']"
        @change="timeEntry.save()"
      />

      <div class="time-range-isGroup">
        {{ totalTime }}
      </div>
      <div
        :class="showPopover ? 'show' : 'hidden'"
        @click="startTimer"
      >
        <i class="fas fa-play play-icon" />
      </div>
      <div :class="showPopover ? 'show' : 'hidden'">
        <OptionsBtn
          :time-entry="timeEntry"
          @show="showPopover = true"
          @hide="showPopover = false"
          @delete-entry="deleteEntry"
        />
      </div>
    </div>

    <transition-group
      v-if="isOpened && isGroup"
      tag="div"
      name="time-entries"
    >
      <div
        v-for="(tEntry, index) in timeEntry"
        :key="index + 1"
        class="time-entries-transition"
      >
        <TimeEntry
          :time-entry="tEntry"
          :one-item="false"
        />
      </div>
    </transition-group>
  </div>
</template>

<script>
import { DateTime, Duration } from 'luxon';
import { TimeEntry } from '@timeragent/core';
import { mapActions } from 'vuex';

export default {
  name: 'TimeEntry',
  components: {
    FolderBtn: () => import('./popovers/FolderBtn.vue'),
    TagBtn: () => import('./popovers/TagBtn.vue'),
    BillableBtn: () => import('./popovers/BillableBtn.vue'),
    OptionsBtn: () => import('./popovers/OptionsBtn.vue'),
  },
  props: {
    timeEntry: {
      type: TimeEntry || [TimeEntry],
      required: true,
    },
    oneItem: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  data() {
    return {
      isOpened: false,
      showPopover: false,
      mainEntry: { ...this.timeEntry[0] } || this.timeEntry,
      tempTitle: this.timeEntry.title || this.timeEntry[0]?.title,
    };
  },
  computed: {
    isGroup() {
      return this.timeEntry.length > 1;
    },
    isShowProjectOrTaskTitle() {
      return this.mainEntry.projectUuid
          || this.mainEntry.taskUuid
          || this.timeEntry.projectUuid
          || this.timeEntry.taskUuid
          || this.showPopover;
    },
    isShowBillable() {
      return this.mainEntry.isBillable
          || this.timeEntry.isBillable
          || this.showPopover;
    },
    totalTime() {
      if (this.isGroup) {
        const millis = this.timeEntry.reduce((res, cur) => res + (DateTime.fromISO(cur.endTime) - DateTime.fromISO(cur.startTime)), 0);

        return Duration.fromMillis(millis).toFormat('h:mm:ss');
      }

      const startTime = DateTime.fromISO(this.timeEntry.startTime);
      const endTime = DateTime.fromISO(this.timeEntry.endTime);

      return Duration.fromMillis(endTime - startTime).toFormat('h:mm:ss');
    },
    changeGridTemplateColumns() {
      if (this.timeEntry.length) {
        return { 'grid-template-columns': '30px 1fr max-content 35px 35px 140px 55px 40px 18px' };
      }

      return { 'grid-template-columns': '1fr max-content 35px 35px 140px 55px 40px 18px' };
    },
    timeRange: {
      get() {
        if (!this.isGroup) {
          return [
            DateTime.fromISO(this.timeEntry.startTime).toMillis(),
            DateTime.fromISO(this.timeEntry.endTime).toMillis(),
          ];
        }

        return [
          DateTime.fromISO(this.timeEntry[this.timeEntry.length - 1].startTime).toFormat('HH:mm'),
          DateTime.fromISO(this.timeEntry[0].endTime).toFormat('HH:mm'),
        ];
      },

      set([start, end]) {
        this.timeEntry.startTime = DateTime.fromMillis(start).toISO();
        this.timeEntry.endTime = DateTime.fromMillis(end).toISO();
      },
    },
  },
  watch: {
    timeEntry: {
      deep: true,
      handler(val) {
        if (!this.isGroup) {
          this.tempTitle = val.title;
        }
      },
    },
  },
  methods: {
    ...mapActions([
      'setActiveTimeEntry',
      'deleteTimeEntry',
    ]),
    async saveInputChange() {
      if (this.isGroup) {
        this.timeEntry.map(async entry => {
          entry.title = this.tempTitle;
          await entry.save();

          return entry;
        });

        return;
      }

      this.timeEntry.title = this.tempTitle;
      await this.timeEntry.save();
    },
    startTimer() {
      const entry = this.isGroup ? this.mainEntry : this.timeEntry;
      const { title, task, taskUuid, project, projectUuid, isBillable } = entry;
      const newEntry = new TimeEntry({ title, task, taskUuid, project, projectUuid, isBillable });

      this.setActiveTimeEntry(newEntry);
    },
    deleteEntry() {
      if (this.isGroup) {
        this.timeEntry.map(e => {
          e.delete();
          this.deleteTimeEntry(e);

          return e;
        });

        return;
      }

      this.timeEntry.delete();
      this.deleteTimeEntry(this.timeEntry);
    },
    onTitleChange(value) {
      this.tempTitle = value;
    },
  },
};
</script>

<style lang="stylus"
       rel="stylesheet/css"
       scoped
>
::v-deep {
  .item-wrapper:hover .el-input__inner, .time-entries-transition .el-input__inner{
    background-color: #f7f7f7;
  }

  .item-wrapper:hover .el-range-editor .el-range-input {
    background-color: #f7f7f7;
  }

  .el-date-editor--datetimerange {
    width: 110px;
  }

  .el-date-editor .el-input__icon {
    display: none;
  }

  .el-date-editor .el-range-separator {
    padding-top: 7px;
  }
}

.item-wrapper {
  display: grid;
  grid-gap: 10px;
  align-items: center;
  justify-items: center;
  height: 50px;
  padding: 0 25px;
  border-bottom: 1px solid #d6d6d6;
}

.item-wrapper:hover {
  background-color: #f7f7f7;
}

.item-wrapper:hover .hidden {
  opacity: 100%
}

.hidden {
  opacity: 0;
}

.show {
  opacity: 100%
}

.counter-entries {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 15px;
  border: 1px solid #909398;
}

.time-range-isGroup {
  display: flex;
  color: #676f7a;
  margin-right: 10px
}

.empty-block {
  width: 28px;
}

.play-icon {
  font-size: 16px;
  color: #909398;
}

.play-icon:hover,
.counter-entries:hover {
  color: #525252;
  cursor: pointer;
}

.time-entries-item {
  display: block;
  margin-right: 10px;
}

.time-entries-enter-active, .time-entries-leave-active {
  transition: all 1s;
}

.time-entries-enter, .time-entries-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.counter {
  width: 29px;
  height: 29px;
  border-radius: 17px;
  border: none;
  background-color: #e9e9e9;
}

.time-entries-transition {
  background-color: #f7f7f7;
  & ::v-deep .el-range-editor .el-range-input {
    background-color: #f7f7f7 !important;
  }
}

</style>
